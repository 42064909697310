<script>
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted, toRefs, watch } from '@vue/composition-api'

/**
 * Recent activity component
 */
export default {
props: {
    idOrder: String
  },
  setup(props)
  {

    const { idOrder } = toRefs(props)

    const { $get } = useHttp()

    const orderStatus = ref({})
     
    const getHistoryOrder = async () => {
      if (typeof idOrder.value !== 'undefined' && idOrder.value !== ''){
        const {data} = await $get({ url: 'master/order_hist/'+idOrder.value})
        orderStatus.value = data
      }else{
        orderStatus.value = {}
      }
    }
    watch(idOrder, getHistoryOrder)

    onMounted(() => {
     getHistoryOrder()
    })

      return{
          orderStatus,
          getHistoryOrder,
      }

  },

  methods : {

     getHistoryOrderi (){ 
     this.getHistoryOrder()
  }

  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Activity History</h4>
      <div data-simplebar>
        <ul class="list-unstyled activity-wid">
          <li class="activity-list" v-for="(data, index) in orderStatus" :key="index">
            <div class="activity-icon avatar-xs">
              <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                <i class="mdi mdi-map-marker"></i>
              </span>
            </div>
            <div>
              <div>
                <h5 class="font-size-13" v-if="typeof data.user !== 'undefined'">{{data.hos_status_datetime}} - {{data.user.usr_fullname}}
                </h5>
                <h5 class="font-size-13" v-else>{{data.hos_status_datetime}}
                </h5>
              </div>
              <div>
                <p class="text-muted mb-0">{{data.hosStatus.descr_en}} 
                  <span v-if="data.hos_remaks">
                    - {{data.hos_remaks}}
                  </span>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>