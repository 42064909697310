<script>
import Layout from "../../layouts/main";
import RecentActivityOrder from "@/components/recent-activity-order";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import Swal from "sweetalert2";

export default {
  page: {
    title: "Update Order Status",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    RecentActivityOrder,Layout, Multiselect
  },
  data() {
    return {
      title: "Update Order Status",
      items: [],
      select_label: "Select",
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true,
      value_flightstatus: null
    };
  },
  setup(){

    const { $get } = useHttp()
    const { $post } = useHttp()

    const LOV = ref({
      orderStatus: [],
    })

    const noOrder = ref()
    const orderStatus = ref()

    const getOrderStatus = async () => { 
      const {data} = await $get({ url: 'master/codes/category/ORS'})
      LOV.value.orderStatus = data
    }

    const orderId = ref('')
    const formDisplay = ref({})
     const form = ref({
      orderStatus: '',
      orderId: '',
      remaks:'',
      })

      const getOrderNo = async () => { 
      const {data} = await $get({ url: 'master/search_order/' +  noOrder.value})
      if(typeof data.ord_id !== 'undefined'){
        form.value.orderId = data.ord_id
        formDisplay.value = data
      }else{
        Swal.fire({
          icon: "error",
          title: 'Order Not Found',
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        form.value.orderId = ''
        formDisplay.value = {}
      }
    }

    const validationForm = async () => {
      orderId.value = form.value.orderId
     let dataSubmit = {
        hosStatuscode : form.value.orderStatus.lbc_code,
        hosOrderId : form.value.orderId,
        hosRemaks : form.value.remaks
     }
      form.value.orderId = ''
      await $post({
        url: 'master/order_status',
        data: dataSubmit
      })
      form.value.orderId = orderId.value
      form.value.orderStatus = {}
      form.value.remaks = ''
    }


    onMounted(() => {
     getOrderStatus()
    })

    return {
      LOV,
      noOrder,
      orderStatus,
      getOrderStatus,
      validationForm,
      form,
      formDisplay,
      getOrderNo,
      orderId
      
    }


  },

  //  methods: {

  //  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
            <div class="col-lg-12">
             <form class="form-horizontal" role="form">
                 <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order/SMU No."
                    label-for="order_no"
                    >
                  <b-form-input v-model="noOrder" ></b-form-input>
                     <div class="button-items">
                    <b-button variant="primary" @click.prevent="getOrderNo">
                      Search
                      </b-button>
                      </div>
                    </b-form-group>
              </form>
              </div>
               <div class="col-12">
                  <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order Status"
                    label-for="order_code"
                  >
                   <multiselect v-model="form.orderStatus" :options="LOV.orderStatus" label="descr_id" track-by="descr_id" :select-label="select_label"></multiselect>
                  </b-form-group>

                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order.No"
                    label-for="order_no"
                  >
                    <b-form-input v-model="form.orderId"  value="1"></b-form-input>
                  </b-form-group> -->
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Remarks"
                    label-for="customer_code"
                  >
                   <b-form-input v-model="form.remaks" ></b-form-input>
                  </b-form-group>

                  </form>

                <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>

              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      
      <div class="col-lg-12">
        <RecentActivityOrder :idOrder="form.orderId"/>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>